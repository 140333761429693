import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import withRoot from '../../withRoot';
import Layout from '../../components/Layout';
import ClientTable from '../../components/ClientTable';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  input: {
    marginTop: 0
  }
});

class Clients extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.getClients();
  }

  componentDidUpdate(prevProps, prevState) {
    const haveFiltersChanged = Object.entries(prevState.filters).some(([key, value]) => value !== this.state.filters[key]);
    const shouldChange = haveFiltersChanged
    || prevState.orderBy !== this.state.orderBy
    || prevState.order !== this.state.order
    || prevState.rowsPerPage !== this.state.rowsPerPage
    || prevState.page !== this.state.page;
    if (shouldChange) {
      this.getClients();
    }
  }

  state = {
    clients: [],
    error: '',
    page: 0,
    rowsPerPage: 20,
    totalCount: 0,
    order: 'desc',
    orderBy: 'first_name',
    filters: {
      first_name: '',
      last_name: '',
      national_id: '',
      phone: '',
      email: '',
      haridus_tase: '',
    },
  };

  handleFilterChange = (filterName, value) => {
    this.setState({
      filters: {
        ...this.state.filters,
        [filterName]: value
      }
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.getClients();
    });
  };
  
  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) }, () => {
      this.getClients();
    });
  };

  handleSort = (property) => {
    const { orderBy, order } = this.state;
    let newOrder;
    if (orderBy === property) {
      if (order === 'asc') {
        newOrder = 'desc';
      } else {
        newOrder = 'asc';
      }
    } else {
      newOrder = 'desc'
    }
    this.setState({
      order: newOrder,
      orderBy: property,
    }, () => {
      this.getClients();
    });
  };

  getClients = () => {
    const { page, rowsPerPage, filters, order, orderBy } = this.state;
    const filteredFilters = Object.fromEntries(Object.entries(filters).filter(([, value]) => value !== null));
    const queryParams = new URLSearchParams({
      ...filteredFilters,
      page: page,
      pageSize: rowsPerPage,
      sortColumn: orderBy,
      sortDirection: order,
    }).toString();

    this.props.axios.get(`get_clients.php?${queryParams}`)
      .then(res => {
        const data = res.data;
        console.log('clients: ', data.rows);
        this.setState({ clients: data.rows, totalCount: data.pagination.total_count });
      })
      .catch(err => {
        if (err.message === 'Request failed with status code 400') {
          this.setState({ error: 'Viga. Proovisid ' });
        } else if (err.message === 'Request failed with status code 403') {
          this.setState({ error: 'Viga. Kasutaja pole administraator.' });
        }
        setTimeout(() => this.setState({ error: '' }), 6000);
      });
  };
  
  render() {
    const { classes } = this.props;

    return (
      <Layout title="Isikud" error={this.state.error}>
        <Grid container spacing={3} className={classes.root}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    placeholder="Eesnimi"
                    value={this.state.filters.first_name}
                    onChange={e => this.handleFilterChange('first_name', e.currentTarget.value)}
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    placeholder="Perekonnanimi"
                    value={this.state.filters.last_name}
                    onChange={e => this.handleFilterChange('last_name', e.currentTarget.value)}
                    fullWidth
                    className={classes.input}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <ClientTable
              classes={classes}
              clients={this.state.clients}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              totalCount={this.state.totalCount}
              onRequestSort={this.handleSort}
            />
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

export default withRoot(withStyles(styles)(Clients));
